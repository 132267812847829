// TODO: no se está usando, se borra?
tr.strikeout td {
    position: relative;

    &:before {
        content: " ";
        position: absolute;
        top: 50%;
        left: 0;
        border-bottom: 1px solid #e3342f;
        width: 100%; }

    &:after {
        content: "\00B7";
        font-size: 1px; } }

.stroked {
    text-decoration: line-through; }

.hand-pointer:hover {
    cursor: pointer; }
